
import React from 'react';
import { Button, Navbar, Nav, Col, Row, Container, Offcanvas  } from 'react-bootstrap';
import Twitter from 'remixicon-react/TwitterFillIcon';
import Telegram from 'remixicon-react/TelegramFillIcon';
import Discord from 'remixicon-react/DiscordFillIcon';
import Moon from './assets/img/moon.png';
import MoonGradient from './assets/img/moon_gradient.png';
import CharacterGradient from './assets/img/character_gradient.png';
import LeftBtn from './assets/img/leftBtn.png';
import RightBtn from './assets/img/rightBtn.png';
import Star from './assets/img/star.gif';
import Harvest from './assets/img/harvest.gif';
import Gap from './assets/img/gap.png';
import ContractSection from './assets/img/contractSection.png';
import Cbox1 from './assets/img/cbox1.png';
import Cbox2 from './assets/img/cbox2.png';
import Intern from './assets/img/intern.png';
import Researcher from './assets/img/researcher.png';
import Scientist from './assets/img/scientist.png';
import Brain from './assets/img/brain.png';
import Genius from './assets/img/genius.png';
import InternBg from './assets/img/internBg.png';
import ResearcherBg from './assets/img/researcherBg.png';
import ScientistBg from './assets/img/scientistBg.png';
import BrainBg from './assets/img/brainBg.png';
import GeniusBg from './assets/img/geniusBg.png';
import Pointer from './assets/img/Pointer.png';
import BasicContract from './assets/img/Basic Contract.png';
import AdvancedContract from './assets/img/Advanced Contract.png';
import ExpertContract from './assets/img/Expert Contract.png';
import InternGif from './assets/img/intern 1.gif';
import ResearcherGif from './assets/img/researcher.gif';
import ScientistGif from './assets/img/scientics.gif';
import BrainGif from './assets/img/brain.gif';
import GeniusGif from './assets/img/genius.gif';
import ContractBg from './assets/img/contractBg.gif';
import Portal from './assets/img/Portal.gif';
import LumenLab from './assets/img/container.gif';
import Leaderboard from './assets/img/firework2.gif';
import LeaderboardItem from './assets/img/Leaderboard Title.png';
import Recruit from './assets/img/recruit3.gif';
import ComingSoon from './assets/img/comingsoon.gif';
import Glitch from './assets/img/glitch.gif';
import Trailer from './assets/img/trailer.mp4';

import './style/index.css';
import WOW from 'wowjs';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HomePage extends React.Component {

    // Defining WOW 
    componentDidMount() {
      new WOW.WOW({
        live: false
    }).init();
    }

    constructor(props) {
        super(props);
        this.state = {
            featureIndex : 0 ,
            characterIndex : 0,
            contractIndex : 0,
            featureAnimating: false,
            featureAnimateDirection: 0,
            featureTitle : [
                'Harvest And earn $MOON',
                'Lumen Lab',
                'Recruit Tinker',
                'Referral',
                'Leaderboard',
                'More Coming Soon'
            ],
            featureDesc : [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
            ],
            featureImg: [
                Harvest,
                LumenLab,
                Recruit,
                Portal,
                Leaderboard,
                ComingSoon
            ],
            characterTitle: [
                'The intern',
                'The Researcher',
                'The Scientist',
                'The Genius',
                'The Brain'
            ],
            characterDesc :[
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
            ],
            characterProduction:[
                '0.001 $MOON/HOUR',
                '0.003 $MOON/HOUR',
                '0.006 $MOON/HOUR',
                '0.009 $MOON/HOUR',
                '0.1 $MOON/HOUR',
            ],
            characterImg: [
                InternGif,
                ResearcherGif,
                ScientistGif,
                GeniusGif,
                BrainGif,
            ],
            characterBgImg: [
                InternBg,
                ResearcherBg,
                ScientistBg,
                GeniusBg,
                BrainBg,
            ],
            contractTitle: [
                'Basic Contract',
                'Advanced Contract',
                'Expert Contract',
            ],
            contractDesc :[
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
            ],
            contractImg: [
                BasicContract,
                AdvancedContract,
                ExpertContract,
            ],
        };
    }

    goToSlide = (sliderName, sliderIndex) => {

        if(this.state[`${sliderName}Animating`]=== true) return true;
        this.setState({ [`${sliderName}Animating`]: true});

        const currentIndex = this.state[`${sliderName}Index`];
        this.setState({ [`${sliderName}AnimateDirection`]: ( sliderIndex < currentIndex)?0:1});

        setTimeout(() => {
            this.setState({ [`${sliderName}Index`]: sliderIndex });
            this.setState({ [`${sliderName}Animating`]: false});
        }, 500);
    };



    goToPrevSlide = (sliderName) => {

        if(this.state[`${sliderName}Animating`]=== true) return true;

        this.setState({ [`${sliderName}Animating`]: true});
        const currentIndex = this.state[`${sliderName}Index`];
        const images = this.state[`${sliderName}Img`];
        const index = (currentIndex === 0) ? images.length - 1 : currentIndex - 1;
        this.setState({ [`${sliderName}AnimateDirection`]: 0});

        setTimeout(() => {
            this.setState({ [`${sliderName}Index`]: index });
            this.setState({ [`${sliderName}Animating`]: false});
        }, 500);
    };

    goToNextSlide = (sliderName) => {

        if(this.state[`${sliderName}Animating`]=== true) return true;

        this.setState({ [`${sliderName}Animating`]: true});
        const currentIndex = this.state[`${sliderName}Index`];
        const images = this.state[`${sliderName}Img`];
        const index = (currentIndex === images.length - 1) ? 0 : currentIndex + 1;
        this.setState({ [`${sliderName}AnimateDirection`]: 1});
        
        setTimeout(() => {
            this.setState({ [`${sliderName}Index`]: index });
            this.setState({ [`${sliderName}Animating`]: false});
        }, 500);
    };
  
        
    render() {

        return (
            <div id='container'>
                <section id='landing'>
                    <h1>Harvest Moon</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                    <div id='socialIcon'>
                        <Twitter size={30}/>
                        <Telegram size={30}/>
                        <Discord size={30}/>
                    </div>
                    <div id='landingTile'>
                        START GAME
                    </div>
                    <div id='moonBox'>
                        <img id='landingMoon' src={Moon} class='rotate-animation'/>
                        <img id='moonGradient' src={MoonGradient}/>
                    </div>
                    <img id='landingStar1' src={Star}/>
                    <img id='landingStar2' src={Star}/>
                </section>    

                {/* Features : Harvest, Leaderboard, Lumen Lab, Referral, Recruit Tinker, Coming Soon */}
                <div id='sectionOuterBox'>
                    <section id='feature'>  
                        <div id='featureLeft'>
                            <div class='sectionTitle'>
                                <div></div>
                                <h3> Game Features </h3>                      
                            </div>
                            <div class={this.state.featureAnimating ? (this.state.featureAnimateDirection == 0) ? 'sliderContent animating-left' :'sliderContent animating-right'  :  (this.state.featureAnimateDirection == 0) ? 'sliderContent animated-right' : 'sliderContent animated-left'}>
                                <h2> {this.state.featureTitle[this.state.featureIndex]}</h2>
                                <p>{this.state.featureDesc[this.state.featureIndex]}</p>
                            </div>
                            <div class='sliderBtn'>
                                <div>
                                    <img src={LeftBtn} class='left' onClick={() => this.goToPrevSlide('feature')}/>
                                    <img src={RightBtn} onClick={() => this.goToNextSlide('feature')}/>
                                </div>
                                <div>
                                    <h4>{this.state.featureIndex+1}/6</h4>
                                </div>
                            </div>
                        </div>
                        <div id='featureRight' class={'featureIndex'+this.state.featureIndex}>
                            <div class={this.state.featureAnimating ? 'sectionRightBox animating'  : 'sectionRightBox'}>
                                <img class='featureImg' src={this.state.featureImg[this.state.featureIndex]}/>
                                <img src={LeaderboardItem} id='leadearboardItem'/>
                                <img src={Glitch} id='featureGlitch'/>
                                <img id='landingStar3' src={Star}/>
                            </div>
                        </div>
                    </section>    
                </div>
                <section id='character'  class={'characterIndex'+this.state.characterIndex}>
                    <div id='characterLeft'>
                        <div id='characterShowcaseBox' class={this.state.characterAnimating?'animating':'animated'}>
                            <img id='characterShowcaseBg' src={this.state.characterBgImg[this.state.characterIndex]}/>
                            <img id='characterShowcase' src={this.state.characterImg[this.state.characterIndex]}/>
                            <img id='characterShowcaseGradient' src={CharacterGradient}/>
                        </div>
                    </div>
                    <div id='characterRight'>
                        <div class='sectionTitle'>
                            <div></div>
                            <h3> Character Showcase </h3>                      
                        </div>
                        <div class={this.state.characterAnimating ? (this.state.characterAnimateDirection == 0) ? 'characterContent animating-left' :'characterContent animating-right'  :  (this.state.characterAnimateDirection == 0) ? 'characterContent animated-right' : 'characterContent animated-left'} >
                            <h2>{this.state.characterTitle[this.state.characterIndex]}</h2>
                            <p>{this.state.characterDesc[this.state.characterIndex]}</p>
                            <div class='characterProduction'> {this.state.characterProduction[this.state.characterIndex]} </div>
                        </div>
                        <div class='characterSlider'>
                            <div class='characterSliderBox'>
                                <div class={this.state.characterIndex === 0? 'characterSelectSection'  :'characterSelectSection inactive'} onClick={() => this.goToSlide('character',0)}>
                                    <img src={Pointer} class='characterPointer'/>
                                    <img class='characterSelectBox' src={Cbox1}/>
                                    <img class='characterSelect' src={Intern}/>
                                </div>
                                <div  class={this.state.characterIndex === 1? 'characterSelectSection'  :'characterSelectSection inactive'}  onClick={() => this.goToSlide('character',1)}>
                                    <img src={Pointer} class='characterPointer'/>
                                    <img class='characterSelectBox' src={Cbox1}/>
                                    <img class='characterSelect' src={Researcher}/>
                                </div>
                                <div class={this.state.characterIndex === 2? 'characterSelectSection'  :'characterSelectSection inactive'}  onClick={() => this.goToSlide('character',2)}>
                                    <img src={Pointer} class='characterPointer'/>
                                    <img class='characterSelectBox' src={Cbox1}/>
                                    <img class='characterSelect' src={Scientist}/>
                                </div>
                                <div class={this.state.characterIndex === 3? 'characterSelectSection'  :'characterSelectSection inactive'}  onClick={() => this.goToSlide('character',3)}>
                                    <img src={Pointer} class='characterPointer'/>
                                    <img class='characterSelectBox' src={Cbox1}/>
                                    <img class='characterSelect' src={Genius}/>
                                </div>
                                <div class={this.state.characterIndex === 4? 'characterSelectSection'  :'characterSelectSection inactive'}  onClick={() => this.goToSlide('character',4)}>
                                    <img src={Pointer} class='characterPointer'/>
                                    <img class='characterSelectBox' src={Cbox1}/>
                                    <img class='characterSelect' src={Brain}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>    
                <section id='contract'  class={'contractIndex'+this.state.contractIndex}>
                    <img src={ContractSection} id='contractBg'/>
                    <div id='contractLeft'>
                        <div class='sectionTitle'>
                            <div></div>
                            <h3> Contract Showcase </h3>                      
                        </div>
                        <div id='contractContent'>
                            <div class={this.state.contractAnimating ? (this.state.contractAnimateDirection == 0) ? 'sliderContent animating-left' :'sliderContent animating-right'  :  (this.state.contractAnimateDirection == 0) ? 'sliderContent animated-right' : 'sliderContent animated-left'}>
                                <h2>{this.state.contractTitle[this.state.contractIndex]}</h2>
                                <p>  {this.state.contractDesc[this.state.contractIndex]} </p>
                            </div>
                            <div class='sliderBtn'>
                                <img class='left' src={LeftBtn} onClick={() => this.goToPrevSlide('contract')}/>
                                <img src={RightBtn} onClick={() => this.goToNextSlide('contract')}/>
                            </div>
                        </div>
                    </div>
                    <div id='contractRight' class={this.state.contractAnimating ?'animating':'animated'}>
                        <div id='contractBox'>
                            <div id='contractTopNav'>
                                <button class={this.state.contractIndex ===0 ?'active':''}  onClick={() => this.goToSlide('contract',0)}>Basic</button>
                                <button  class={this.state.contractIndex ===1 ?'active':''}   onClick={() => this.goToSlide('contract',1)}>Advanced</button>
                                <button  class={this.state.contractIndex ===2 ?'active':''}   onClick={() => this.goToSlide('contract',2)}>Expert</button>
                            </div>
                            <div id='contractShowcaseBox'>
                                <img src={this.state.contractImg[this.state.contractIndex]} id='contractShowcase'/>
                                <img src={this.state.contractImg[this.state.contractIndex]} id='contractShowcaseShadow'/>
                                <img src={ContractBg} id='contractShowcaseBg' />
                                <img src={Glitch} id='contractGlitch' />
                            </div>
                        </div>
                    </div>
                </section>    
                <section id='video'>
                    
                    <video autoPlay muted loop>
                        <source src={Trailer} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div class='sectionTitle'>
                        <h3> Game trailer </h3>                      
                        <div></div>
                    </div>
                </section>    
                <footer>
                    <div>
                        <p> Copyright©2024 Meteor Wallet All Rights Reserved </p>
                    </div>
                    <div id='footerIcon'>
                        <Twitter size={28}/>
                        <Telegram size={28}/>
                        <Discord size={28}/>
                    </div>
                </footer>
            </div>
        );
    }
}

export default HomePage;
